.social-icons
  display: flex
  justify-content: center
  align-items: baseline

.social-icons__icon
  width: 1.2rem
  height: 1.2rem
  background-size: contain
  background-repeat: no-repeat

  &>svg
    color: $white

  &:not(:last-child)
    margin-right: 2rem