@import '../_main'
@import '../partials/_social-icons'

.splash-container
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  font-size: 14px

  @media screen and (min-width: $medium)
    font-size: 18px
    justify-content: left



.splash
  color: $white

  h1
    font-size: $splashH1size 
    line-height: 1
    letter-spacing: -0.03em
    margin: 0

  h2
    font-size: 2.25em
    font-weight: 500
    line-height: 1.25
    max-width: 22em
    letter-spacing: -0.03em
  
  @media screen and (min-width: $medium)
    margin-left: 100px

.fancy
  color: $primary

.handle
  display: inline-block
  margin-top: 0.275em
  color: $grey
  letter-spacing: 0.5px

.writing
  text-decoration: none
  color: $primary

/* overrides */

body
  background: $white
  background: linear-gradient(70deg, rgba(77, 186, 174, 0.5), rgba(28, 77, 112, 0.5));

main
  padding-top: 0
  padding-bottom: 0
  height: 100%

.social-icons
  justify-content: flex-start
  padding-top: 2rem
