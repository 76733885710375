html,
body 
  background-color: $white
  color: $black
  height: 100%

html
  box-sizing: border-box
  
*,
*:before,
*:after
  padding: 0
  margin: 0
  box-sizing: inherit 